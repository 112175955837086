
import { defineComponent, onMounted, ref } from 'vue';
import { getList } from '@/api/verify';
import Logo from '@/assets/logo.svg?inline';
import { clearRedirect, setRedirectIn } from '../../tools';
import Loader from '@/components/Loader.vue';
import { clearMarkedVerified, markVisitVerified } from '@/api/visit';
import Dialog from '@/components/Dialog.vue';

export default defineComponent({
  components: { Dialog, Loader, Logo },
  setup() {
    const name = ref('');

    const members = ref<VerifyList>([]);

    const selected = ref(-1);

    const visibleMembers = ref<VerifyList>([]);

    const state = ref('initial');

    let lastMarked: VerifyListItem | null = null;

    function sortList() {
      resetTimer();
      if (name.value.length < 1) {
        visibleMembers.value = [];
        return;
      }
      visibleMembers.value = members.value
        .filter(v => nameRank(v.name.toLowerCase()) > 0)
        .sort(rankSort);
    }

    function nameRank(v: string): number {
      const n = name.value.toLowerCase();
      let rank = 0;
      if (n === v) rank += 12;
      if (v.startsWith(n)) rank += 2;
      if (v.indexOf(n) >= 0) rank += 1;
      return rank;
    }

    function rankSort(a: VerifyListItem, b: VerifyListItem): number {
      return nameRank(b.name.toLowerCase()) - nameRank(a.name.toLowerCase());
    }


    onMounted(async () => {
      members.value = await getList();
    });

    async function inputKey(e: KeyboardEvent) {
      if (e.code === 'Enter' && visibleMembers.value.length > 0) {
        await markAttendance(visibleMembers.value[0]);
      }
      resetTimer()
    }

    function resetTimer() {
      setRedirectIn('home', 10);
    }

    async function enterFocusRow(e: KeyboardEvent, row: VerifyListItem) {
      if (e.code === 'Enter') {
        await markAttendance(row);
      }
    }

    async function done() {
      if (selected.value === -1) return;
      const item = visibleMembers.value[selected.value];
      await markAttendance(item);
    }

    function complete() {
      state.value = 'complete';
      setRedirectIn('home', 2);
    }

    async function markAttendance(item: VerifyListItem) {
      state.value = 'loading';
      lastMarked = item;
      try {
        clearRedirect();
        const timeStart = performance.now();
        await markVisitVerified(item._id, item.name);
        const duration = performance.now() - timeStart;
        if (duration < 1000) {
          setTimeout(complete, 1000 - duration);
        } else {
          complete();
        }
      } catch (e: any) {
        if (e.response) {
          const { status } = e.response;
          if (status === 422) {
            state.value = 'already-marked';
            resetTimer();
          } else if (status === 403) {
            state.value = 'verify-expired';
            resetTimer();
          }
        } else {
          state.value = 'error-message';
        }
      }
    }

    function selectIndex(index: number) {
      selected.value = index;
      resetTimer();
    }

    resetTimer();

    function undo() {
      if (lastMarked != null) {
        clearMarkedVerified(lastMarked._id);
        state.value = 'initial';
        resetTimer();
      }
    }

    return {
      name, inputKey, visibleMembers,
      done, selected, sortList, enterFocusRow,
      selectIndex, state, undo,
    };
  },
  mounted() {
    (this.$refs.nameInput as HTMLInputElement).focus();
  },
});
