
import { defineComponent, onMounted, ref } from 'vue';
import Loader from '@/components/Loader.vue';
import Dialog from '@/components/Dialog.vue';
import DOBPicker from '@/components/DOBPicker.vue';
import { setRedirectIn } from '../../tools';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: { DOBPicker, Dialog, Loader },
  setup() {

    const { state } = useStore();
    const { push } = useRouter();
    const password = ref('');

    function verify() {
      if (password.value == state.admin) {
        push({ name: 'admin' });
      } else {
        alert('INCORRECT PASSWORD');
      }
    }

    function resetTimer() {
      setRedirectIn('home', 10);
    }

    resetTimer();

    return { password, resetTimer, verify };
  },
});
