
import { defineComponent, ref } from 'vue';
import QRScanner from '@/components/QRScanner.vue';
import Logo from '@/assets/logo.svg?inline';
import { confirmVerify, startVerify } from '@/api/verify';
import Loader from '@/components/Loader.vue';
import Dialog from '@/components/Dialog.vue';
import { VACCINE_PASS_REGEX } from '@/constants';
import { useRouter } from 'vue-router';
import { setRedirectIn } from '../../tools';

export default defineComponent({
  components: { Dialog, Loader, QRScanner, Logo },
  setup() {
    const state = ref('scanning');
    const verifyResult = ref<VerifyDetails>({ name: '', dob: '' });
    const dataURI = ref('');

    async function completeScan(uri: string) {
      dataURI.value = uri;
      state.value = 'loading';
      if (!VACCINE_PASS_REGEX.test(uri)) {
        state.value = 'invalid-code';
        return;
      }
      try {
        const timeStart = performance.now();
        const { data } = await startVerify(uri);
        const duration = performance.now() - timeStart;
        /*
        * If the request took < 3000ms add the remaining time
        * to give them a false sense of security >:)
        */
        if (duration < 3000) {
          setTimeout(() => nextStep(data), 3000 - duration);
        } else {
          await nextStep(data);
        }
      } catch (e: any) {
        if (e.response) {
          const { status } = e.response;
          if (status === 422) {
            state.value = 'already-verified';
          } else if (status === 400) {
            state.value = 'invalid-code';
          }else {
            state.value = 'error-message';
          }
        } else {
          state.value = 'error-message';
        }
      }
    }

    async function nextStep(data: VerifyDetails) {
      state.value = 'confirm';
      verifyResult.value = data;
    }

    async function confirm() {
      const uri = dataURI.value;
      state.value = 'loading-confirm';
      if (!VACCINE_PASS_REGEX.test(uri)) {
        state.value = 'invalid-code';
        return;
      }
      try {
        const timeStart = performance.now();
        await confirmVerify(uri);
        const duration = performance.now() - timeStart;
        if (duration < 3000) {
          setTimeout(complete, 3000 - duration);
        } else {
          complete();
        }
      } catch (e: any) {
        if (e.response) {
          const { status } = e.response;
          if (status === 422) {
            state.value = 'already-verified';
            setRedirectIn('home', 10);
          } else if (status === 400) {
            state.value = 'invalid-code';
          }
        } else {
          state.value = 'error-message';
        }
      }
    }

    function complete() {
      state.value = 'complete';
      setRedirectIn('home', 10);
    }

    return { state, verifyResult, completeScan, confirm };
  },
});
