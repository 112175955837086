import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9f538878"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "checkbox__box" }
const _hoisted_2 = { class: "checkbox__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Check = _resolveComponent("Check")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([{'checkbox--checked':_ctx.value}, "checkbox"]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.value = !_ctx.value))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_Transition, { name: "scale" }, {
        default: _withCtx(() => [
          (_ctx.value)
            ? (_openBlock(), _createBlock(_component_Check, {
                key: 0,
                class: "checkbox__box__icon",
                icon: "check"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.text), 1)
  ], 2))
}