
import { defineComponent, reactive, ref, watch } from 'vue';
import { DateTime } from 'luxon';

export default defineComponent({
  emits: [ 'change' ],
  setup(_, { emit }) {

    const now = new Date();

    const date = reactive({
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      date: now.getDate(),
    });

    const years = possibleYears();
    const months = ref(possibleMonths());
    const days = ref(possibleDays());

    watch(date, () => days.value = possibleDays());
    watch(date, () => months.value = possibleMonths());

    function possibleYears() {
      return listOfNumbers(2000, now.getFullYear());
    }

    function possibleMonths() {
      if (date.year == now.getFullYear()) {
        const cm = now.getMonth() + 1;
        if (date.month > cm) date.month = cm;
        return listOfNumbers(1, cm);
      }
      return listOfNumbers(1, 12);
    }

    function possibleDays() {
      let total = new Date(date.year, date.month, 0).getDate();
      const cd = now.getDate();
      if (date.year == now.getFullYear() && date.month == now.getMonth()) {
        if (total > cd) total = cd;
      }
      if (date.date > total) date.date = total;
      return listOfNumbers(1, total);
    }

    function listOfNumbers(min: number, max: number) {
      const list = [];
      for (let i = min; i <= max; i++) {
        list.push(i);
      }
      return list;
    }

    watch(date, () => emit('change', DateTime.fromObject({ year: date.year, month: date.month, day: date.date })));

    return { years, months, days, date };
  },
});
