
import { defineComponent, ref } from 'vue';
import Loader from '@/components/Loader.vue';
import Dialog from '@/components/Dialog.vue';
import DOBPicker from '@/components/DOBPicker.vue';

import { DateTime, Interval } from 'luxon';
import { verifyU12 } from '@/api/verify';
import { useRouter } from 'vue-router';
import { setRedirectIn } from '../../tools';

export default defineComponent({
  components: { DOBPicker, Dialog, Loader },
  setup() {

    const state = ref('initial');

    const date = ref<DateTime>(DateTime.now());
    const name = ref('');

    function changeDate(data: DateTime) {
      date.value = data;
    }

    function isValidDate(): boolean {
      const now = DateTime.now();
      const other = date.value as DateTime;
      const diff = Interval.fromDateTimes(other, now);
      const years = diff.length('years');
      return years <= 12.3; // added the extra .05 because floating points go yes
    }

    function complete() {
      state.value = 'complete';
      setRedirectIn('home', 10);
    }

    async function verify() {
      if (!isValidDate()) {
        state.value = 'too-old';
        setRedirectIn('home', 10);
        return;
      }
      state.value = 'loading';
      try {
        const timeStart = performance.now();
        await verifyU12(name.value, date.value as DateTime);
        const duration = performance.now() - timeStart;
        if (duration < 2000) {
          setTimeout(complete, 2000 - duration);
        } else {
          complete();
        }
      } catch (e: any) {
        if (e.response) {
          const { status } = e.response;
          if (status === 422) {
            state.value = 'already-verified';
            setRedirectIn('home', 10);
          }else {
            state.value = 'error-message';
          }
        } else {
          state.value = 'error-message';
        }
      }
    }

    return { date, changeDate, name, verify, state };
  },
  mounted() {
    (this.$refs.nameInput as HTMLInputElement).focus();
  },
});
