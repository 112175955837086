
import { defineComponent, onMounted, ref } from 'vue';
import Checkbox from '@/components/Checkbox.vue';
import { useStore } from 'vuex';
import DOBPicker from '@/components/DOBPicker.vue';
import { DateTime } from 'luxon';
import { getAllForDate, removeVisit } from '@/api/visit';

export default defineComponent({
  components: { DOBPicker, Checkbox },
  setup() {
    const { state } = useStore();

    const attending = ref<VisitList>([]);
    const date = DateTime.now();

    onMounted(async () => {
      try {
        const { data } = await getAllForDate(DateTime.now());
        attending.value = data;
      } catch (e) {
        alert('Failed to load attending list: ' + e);
      }
    });

    async function removeVisitC(item: VisitListItem) {
      try {
        await removeVisit(date, item);
        attending.value = attending.value.filter(v => v._id !== item._id);
      } catch (e) {
        alert('Failed to load attending list: ' + e);
      }
    }

    function formatTime(time: string) {
      return DateTime.fromISO(time).toLocaleString(DateTime.TIME_SIMPLE);
    }

    return { attending, formatTime, removeVisit: removeVisitC };
  },
});
