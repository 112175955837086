
import { defineComponent } from 'vue';
import Logo from '@/assets/logo.svg?inline';

export default defineComponent({
  components: { Logo },
  props: { title: String, message: String },
  setup() {
  },
});
