
import { defineComponent, ref, toRef, watch } from 'vue';
import Checkbox from '@/components/Checkbox.vue';
import { useStore } from 'vuex';
import DOBPicker from '@/components/DOBPicker.vue';
import { DateTime } from 'luxon';
import { addMemberAdmin, setSetting } from '@/api/admin';
import Loader from '@/components/Loader.vue';

export default defineComponent({
  components: { Loader, DOBPicker, Checkbox },
  setup() {
    const { state } = useStore();
    const mandate = toRef(state, 'mandate');

    const fullName = ref('');
    const date = ref<DateTime>(DateTime.now());

    const stateNVMessage = toRef(state, 'nvMessage');
    const nvMessage = ref(state.nvMessage);
    watch(stateNVMessage, () => nvMessage.value = stateNVMessage.value);

    const loading = ref(false);

    watch(date, () => console.log(date));

    watch(mandate, async () => {
      loading.value = true;
      try {
        const timeStart = performance.now();
        await setSetting('mandate', mandate.value);
        const duration = performance.now() - timeStart;
        /*
        * If the request took < 3000ms add the remaining time
        * to give them a false sense of security >:)
        */
        if (duration < 500) {
          setTimeout(() => loading.value = false, 500 - duration);
        } else {
          loading.value = false;
        }
      } catch (e) {
        loading.value = false;
      }
    });

    async function addMember() {
      const name = fullName.value;
      const dob = date.value;
      loading.value = true;
      try {
        const timeStart = performance.now();
        await addMemberAdmin(name, dob);
        const duration = performance.now() - timeStart;
        if (duration < 500) {
          setTimeout(() => loading.value = false, 500 - duration);
        } else {
          loading.value = false;
        }
      } catch (e) {
        loading.value = false;
      }

    }

    async function setNotVaccinatedMessage() {
      const message = nvMessage.value;
      loading.value = true;
      try {
        const timeStart = performance.now();
        await setSetting('nvMessage', message);
        state.nvMessage = message;
        const duration = performance.now() - timeStart;
        if (duration < 500) {
          setTimeout(() => loading.value = false, 500 - duration);
        } else {
          loading.value = false;
        }
      } catch (e) {
        loading.value = false;
      }
    }

    return { mandate, date, fullName, nvMessage, addMember, setNotVaccinatedMessage, loading };
  },
});
