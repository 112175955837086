
import { defineComponent, toRef } from 'vue';
import Logo from '@/assets/logo.svg?inline';
import { useStore } from 'vuex';

export default defineComponent({
  components: { Logo },
  setup() {
    const { state } = useStore();
    const mandate = toRef(state, 'mandate');

    return { mandate };
  },
});
