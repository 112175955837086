
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import QrScanner from 'qr-scanner';

export default defineComponent({
  emits: [ 'scanned' ],
  setup(_, { emit }) {

    const videoRef = ref<HTMLVideoElement | null>(null);

    let scanner: QrScanner | null = null;

    onMounted(async () => {
      scanner = new QrScanner(videoRef.value as HTMLVideoElement, result => {
        if (result.length < 1) return;
        emit('scanned', result);
      });
      await scanner.start();
    });

    onUnmounted(() => {
      scanner?.stop();
      scanner?.destroy()
    });

    return { videoRef };
  },
});
