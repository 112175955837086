
import { computed, defineComponent, toRef } from 'vue';
import Check from '@/assets/check.svg?inline';


export default defineComponent({
  emits: [ 'update:modelValue', 'change' ],
  props: {
    modelValue: { type: Boolean, default: false },
    text: { type: String, required: true },
  },
  components: { Check },
  setup(props, { emit }) {
    const modelValue = toRef(props, 'modelValue');
    const value = computed({
      get() {
        return modelValue.value;
      },
      set(value: boolean) {
        emit('update:modelValue', value);
        emit('change');
      },
    });
    return { value };
  },
});
